<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="customerPropertyDialog"
      :max-width="dialogWidth"
      content-class="customer-property-dialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">
            Select a Service Location
          </h4>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            color="cyan"
            v-on:click="
              $emit('close', true);
              $emit('resetAll', true);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which property would you like to create this for?
              </h5>
              <table width="100%">
                <tr>
                  <td>
                    <v-text-field
                      ref="search-customer-property"
                      v-model.trim="customerProperty"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <template v-if="false && getPermission('property:create')">
                    <td width="50" class="custom-header-blue-text text-center">
                      OR
                    </td>
                    <td>
                      <v-btn
                        class="mx-2 mx-md-4 custom-bold-button white--text"
                        v-on:click="createPropertyDialog = true"
                        color="cyan"
                      >
                        <v-icon dark left>mdi-plus</v-icon>Service Location
                      </v-btn>
                    </td>
                  </template>
                </tr>
              </table>

              <div class="pt-4">
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <v-list class="mx-2 mx-md-4" subheader three-line>
                    <v-skeleton-loader
                      v-if="searchLoader"
                      class="custom-skeleton"
                      type="list-item-two-line"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <template v-if="customerPropertyList.length > 0">
                        <v-list-item
                          v-on:click="selectCustomerProperty(data)"
                          v-for="(data, index) in customerPropertyList"
                          :key="index"
                          link
                        >
                          <v-list-item-icon class="mr-3">
                            <v-icon large>mdi-home-map-marker</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-layout style="max-width: 150px">
                              <v-flex>
                                <Barcode
                                  class="my-0 mt-0 mb-0 mr-1"
                                  :barcode="data.barcode"
                                ></Barcode>
                              </v-flex>
                              <v-flex v-if="data.is_tenant">
                                <v-chip
                                  class="mx-1"
                                  label
                                  small
                                  color="green"
                                  outlined
                                  >Tenant</v-chip
                                >
                              </v-flex>
                              <v-flex>
                                <v-chip
                                  label
                                  small
                                  class="ml-1"
                                  :color="data.type == 1 ? 'cyan' : 'orange'"
                                  outlined
                                >
                                  <template v-if="data.type == 1"
                                    >Property</template
                                  >
                                  <template v-else>Billing</template>
                                </v-chip>
                              </v-flex>
                            </v-layout>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                              >{{ getFormattedProperty(data) }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >No Service Location(s) Found
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Dialog
      v-if="createPropertyDialog"
      :commonDialog="createPropertyDialog"
      :dialogWidth="768"
    >
      <template v-slot:title>Create Property</template>
      <template v-slot:body>
        <v-form
          ref="createProperty"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="createProperty"
        >
          <DialogPropertyAddress
            :isDialog="true"
            v-on:savePropertyAddress="updateProperty"
          >
          </DialogPropertyAddress>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="createProperty"
          >Save Property
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="createPropertyDialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import CustomerMixin from "@/core/plugins/customer-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import DialogPropertyAddress from "@/view/pages/customer/create-or-update/Property-Address.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  mixins: [CustomerMixin],
  data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      customerProperty: null,
      timeoutLimit: 500,
      timeout: null,
      propertyAddress: new Object(),
      createPropertyDialog: false,
      customerPropertyList: new Array(),
    };
  },
  watch: {
    customerProperty(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchCustomerProperty();
        }, _this.timeoutLimit);
      }
    },
    customer() {
      this.searchCustomerProperty(null);
    },
  },
  props: {
    disabledAutoSelect: {
      type: Boolean,
      default: false,
    },
    customerPropertyDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  components: {
    Dialog,
    Barcode,
    DialogPropertyAddress,
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchCustomerProperty();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchCustomerProperty();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.customerProperty = null;
        _this.isFocused = false;
      });
    },
    searchCustomerProperty() {
      const _this = this;
      _this.searchLoader = true;
      _this
        .getCustomerProperties(_this.customer, _this.customerProperty)
        .then((response) => {
          _this.customerPropertyList = response;
          if (
            !_this.disabledAutoSelect &&
            _this.customerPropertyList.length === 1
          ) {
            _this.$emit(
              "selectCustomerProperty",
              _this.customerPropertyList[0].id
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.createPropertyDialog = false;
          _this.searchLoader = false;
        });
    },
    selectCustomerProperty(param) {
      this.$emit("selectCustomerProperty", param.id);
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country_name) === false) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },
    updateProperty(param) {
      this.propertyAddress = param;
    },
    createProperty() {
      const _this = this;
      if (!_this.$refs.createProperty.validate()) {
        return false;
      }
      let addressFormData = new Array();
      addressFormData.push(_this.propertyAddress);
      const formData = _this.lodash.pickBy(
        addressFormData,
        _this.lodash.identity
      );

      if (_this.lodash.isEmpty(formData) === false) {
        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});
        _this.$store
          .dispatch(PUT, {
            url: "address/" + _this.customer,
            data: { address: formData },
          })
          .then(() => {
            _this.searchCustomerProperty();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
  },
  mounted() {
    this.searchCustomerProperty();
    this.$refs["search-customer-property"].focus();
  },
  computed: {
    isSearching() {
      return this.customerProperty && this.customerProperty.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Service Location...";
    },
  },
};
</script>
